﻿import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators} from '@angular/forms'

export function regExValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

        var regEx1: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$');
        var regEx2: RegExp = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*(_|[-+_!@#$%^&*.,?])).+$');
        var regEx3: RegExp = new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*(_|[-+_!@#$%^&*.,?])).+$');
        var regEx4: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*(_|[-+_!@#$%^&*.,?])).+$');
        const enteredValue = control.value;
        if (enteredValue != null && enteredValue.trim() !== "") {
            var isValid1 = regEx1.test(enteredValue);
            var isValid2 = regEx2.test(enteredValue);
            var isValid3 = regEx3.test(enteredValue);
            var isValid4 = regEx4.test(enteredValue);
            var isValid = isValid1 || isValid2 || isValid3 || isValid4;
            return isValid ? null : { 'regExValue': { enteredValue } };
        }

        return enteredValue;
    };
}

@Directive({
    selector: '[regExValue]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RegExValidatorDirective, multi: true }]
})
export class RegExValidatorDirective implements Validator, OnChanges {
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['regExValue'];
        if (change) {
            const val: string | RegExp = change.currentValue;
            this.valFn = regExValidator();
        } else {
            this.valFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }
}
'use strict';
import * as Globals from "./globals"; 

//Standard error messages
export var errSystemException: string[] = ['Please contact the system administrator:', 'Error!'];

//Login Error Messages
export var errInvalidUser: string[] = ['Unable to Login, please contact the system administrator','User Login Failed!'];
export var errInvalidCredentials: string[] = ['Unable to Login. Your account has been disabled, please contact the system administrator: ', 'User Account Disabled!'];
export var errInActiveUser: string[] = ['Unable to Login, your account is Inactive. Please contact the system administrator: ', 'User Account InActive!'];
export var errUserDisabled: string[] = ['Unable to Login, Username and/or Password is invalid', 'Incorrect Credentials!'];
export var errUserNotRegistered: string[] = ['Unable to Login, User not registered', 'Invalid User!'];
export var siteAccessNotAllowed: string[] = ['User not allowed to login. please contact Admin: ', 'Site access not allowed!'];
export var warnPasswordExpiry1Day: string[] = ['Your password is expiring in 1 day. You are being redirected to the Change password screen', 'Password Expiry'];
export var warnPasswordExpired: string[] = ['Your password has expired, and you are being redirected to Change password screen', 'Password Expired'];
export var warnPasswordExpiredDisabled: string[] = ['Your password has been expired for 30 days or more and your account has been disabled! Please contact the system administrator.', 'Password Expired'];
export var errCaptchaRequired: string[] = ['Please verify that you are not a robot', 'Captcha!'];
export var errUserActiveDirExp: string[] = ['Unable to Login, User not present in Active Directory', 'Invalid User!'];

//Session timeout messages
export var infoSessionTimeOut: string[] = ['Your session is about to expire due to inactivity! You will be logged out in 1 minute. Click anywhere to stay signed in', 'Session Timeout'];
export var infoSessionTimedOut: string[] = ['Your session has timed out. Please sign in again', 'Session Timeout'];

// terms and conditions Messages
export var termsAndConditionsFail: string[] = ['Error in accepting terms and conditions. Please try again.', 'Error!'];
export var termsAndConditionsSuccess: string[] = ['Thanks for accepting the Terms & Conditions', 'Confirmation!'];
export var termsAndConditionsWarning: string[] = ['You have been logged out of the system, as you did not accept the Terms and Conditions of our site.', 'Information!'];

// reset password Messages
export var resetPasswordFail: string[] = ['Error in resetting terms and conditions. Please try again.', 'Error!'];
export var resetPasswordSuccess: string[] = ['Your password has been successfully reset!', 'Confirmation!'];

// change password Messages
export var changePasswordFail: string[] = ['Error in changing the password. Please try again.', 'Error!'];
export var changePasswordSuccess: string[] = ['Your password has been successfully updated!', 'Success!'];
export var changePasswordCredentialCheckFail: string[] = ['Your Current password entered does not match our records. Please re-enter', 'Warning!'];
export var changePasswordPasswordMismatch: string[] = ['Your New password and Confirmation Password does not match. Please re-enter!', 'Warning!'];
export var changePasswordUserDisabled: string[] = ['Unable to Login. Your account has been disabled, please contact the system administrator email: ', 'User Account Disabled!'];
export var changePasswordSameAsLastFour: string[] = ['The entered password matches one of your last four passwords. Please choose a new password!', 'Warning!'];
export var changePasswordError: string[] = ['An error occurred while changing the password. Please try again!', 'Error!'];


// password Reset
export var resetPasswordLinkExpired: string[] = ['The Reset password link has expired. Please generate another one!', 'Warning!'];
export var resetPasswordLinkProcessed: string[] = ['The Reset password link has already processed.', 'Information!'];
export var resetPasswordError: string[] = ['An error occurred while resetting the password. Please try again!', 'Error!'];
export var resetPasswordInActiveUser: string[] = ['Cannot reset the password.The user is inactive.Please contact the system administrator: ', 'Inactive user!'];


// new password policy validation messages
export var newPasswordRequired: string[] = ['New password is required.', 'required'];
export var newPasswordForbiddenName: string[] = ['New Password should not contain the IT system users full name.', 'forbiddenName'];
export var newPasswordRepeatedCharacters: string[] = ['New password should not contain a string of three or more identical or consecutive characters.', 'repeatedCharacters'];
export var newPasswordNotAllowedCharacters: string[] = ['New password does not match the password strength.', 'regExValue'];
export var newPasswordMinLength: string[] = ['New password should be at least 10 characters long.', 'minlength'];

// confirm password policy validation messages
export var confirmPasswordRequired: string[] = ['Confirm password is required.', 'required'];
export var confirmPasswordForbiddenName: string[] = ['Confirm Password should not contain the IT system users full name.', 'forbiddenName'];
export var confirmPasswordRepeatedCharacters: string[] = ['Confirm password should not contain a string of three or more identical or consecutive characters.', 'repeatedCharacters'];
export var confirmPasswordNotAllowedCharacters: string[] = ['Confirm password does not match the password strength.', 'regExValue'];
export var confirmPasswordMinLength: string[] = ['Confirm password should be at least 10 characters long.', 'minlength'];

// current password policy validation messages
export var currentPasswordRequired: string[] = ['Current password is required.', 'required'];

// register external user validation messages
export var registerUserLinkExpired: string[] = ['The Registration link has expired. Please contact the system administrator! ', 'Warning!'];
export var registerUserLinkInvalid: string[] = ['The Registration link is invalid. Please check the link!', 'Warning!'];
export var registerUserRegistered: string[] = ['You are a registered Fetch user', 'Warning!'];

//register user messages
export var registerUserSuccess: string[] = ['You have been successfully registered!', 'Confirmation!'];
export var registerUserError: string[] = ['An error occurred while registering the user. Please try again!', 'Error!'];

// register sponsor contact user messages
export var registerSponsorContactSuccess: string[] = ['Registration link has been sent to the Sponsor contact.', 'Confirmation!'];
export var registerSponsorContactError: string[] = ['An error occurred while registering a sponsor contact. Please try again.', 'Error!!'];
export var registerSponsorContactInactiveUserWarning: string[] = ["Cannot provide access to inactive contact!.", "Information!"];
export var registerSponsorContactValidationMessage: string[] = ["Global study number does not exist or it is not active.", "Validation"];
export var registerSponsorContactValidationMessageForInactiveTrialLeader: string[] = ["Sponsor contact cannot be registered for the inactive trial leader.", "Warning"];
export var registerSponsorContactValidationMessageForClosedStudy: string[] = ["Sponsor contact cannot be registered for the closed study.", "Warning"];
export var updateConsolidatedEmailSuccess: string[] = ["Updated consolidated email setting successfully.", "Success"];
export var updateEnableKitTransferSuccess: string[] = ["Updated enable kit transfer successfully","Success"];
export var updateCTCDStudySuccess: string[] = ["Study Switch from CT to CD/ CD to CT successfully","Success"];
export var updateEcomPortalAccessSuccess: string[] = ["Updated Ecom Portal Access setting successfully.", "Success"];
export var updateEcomAapproveOrdersSuccess: string[] = ["Updated Ecom Approve Orders  setting successfully.", "Success"];
export var updateEcomShowPriceSuccess: string[] = ["Updated Ecom Show Price setting successfully.", "Success"];
export var updateEnableKitPreBuildSuccess: string[] = ["Updated bulk pre-build of kits successfully.","Success"];
export var updateShipSamplesWithOpenQuerySuccess: string[] = ["Updated Ship Samples With OpenQuery successfully.","Success"];

export var adUserNotValid: string[] = ['The PUID entered is not valid, please re-enter!', 'Warning!'];
export var newUserRegistrationSuccess: string[] = ['New Internal user added successfully.', 'Success!'];
export var newUserRegistrationDuplicateWarning: string[] = ['User already exists in the system.', 'Warning!'];
export var userRegistrationUpdateSuccess: string[] = ['Internal User Details have been updated successfully', 'Success!'];

// Investigator Site related messages
export var siteDoesNotExistMessage: string[] = ['Hospital or Institute Name does NOT exist.', 'Warning!'];
export var addSiteSuccessMessage: string[] = ['New Investigator Site added successfully.', 'Success'];
export var editSiteSuccessMessage: string[] = ['Investigator Site Details have been updated successfully.', 'Success'];
export var siteLinkedToActiveStudies: string[] = ['The investigator site {siteName} is linked to an active study. Your Changes would affect the studies that this site is linked to!', 'Warning!'];
export var siteUpdateConfirmation: string[] = ['Are you sure you want to update the Investigator Site details ?', 'Confirmation!'];

//My Studies
export var fileSizeMessage: string[] = ['File size cannot exceed 20 MB.', 'Warning!'];
export var fileTypeIncorrectMessage: string[] = ['Incorrect file type selected. Please choose a jpeg file.', 'Warning!'];
export var titleSuccessMessage: string[] = ['Study Title saved sucessfully.', 'Success'];
export var imgSuccessMessage: string[] = ['Study Image uploaded successfully.', 'Success'];

//Study Site
export var siteNumberNotUnique: string[] = ['This Site Number is already taken for this Study! Please choose another one.', 'Warning!'];
export var siteNameNotUnique: string[] = ['This Site Name is already taken for this Study! Please choose another one.', 'Warning!'];
export var siteDuplicateError: string[] = ['This hospital and department combination already exists for this study. Please choose another one.', 'Warning!'];

//Site contacts wizard tab
export var siteContactNotUnique: string[] = ['This Contact is already added in this Site as Site Contact! Please choose another one.', 'Warning!']; 
//Upload file
export var uploadfileTypeIncorrectMessage: string[] = ['File format is not supported by the system, please upload another file!', 'Warning!'];
export var uplaodfileSizeMessage: string[] = ['Uploaded file is beyond the size supported by the system. max size supported is 20MB', 'Warning!'];
export var uploadSuccessMessage: string[] = ['uploaded successfully!, Pending approval by the system administrator', 'Success'];
export var uploadSuccessMessageForInternalUser: string[] = ['uploaded successfully!', 'Success'];
export var uploadFileNameLengthMessage: string[] = ['FileName length should be maximum 125 character', 'Warning!'];
export var uploadFileDuplicateMessage: string[] = ["File of the same name already exists for this study. Please rename the file or choose another one!", 'Warning!'];
export var fileApprovalSuccessMessage: string[] = ['The file is approved successfully!, Pending to be shared by the system administrator', 'Success'];
export var fileRejectionSuccessMessage: string[] = ['The file has been rejected successfully! Notification email has been sent.!', 'Success'];
export var fileShareSuccessMessage: string[] = ['The file is shared successfully!', 'Success'];
export var studyFileUpdateSuccessMessage: string[] = ['The file is shared to studies successfully!', 'Success'];
export var studiesSelectionWaningMessage: string[] = ['Studies have to be selected to share the files.!', 'Warning'];
//Stability Files 
export var stabilityUploadfileTypeIncorrectMessage: string[] = ['File format is not supported by the system, please upload another file!', 'Warning!'];
export var stabilityUplaodfileSizeMessage: string[] = ['Uploaded file is beyond the size supported by the system. max size supported is 20MB', 'Warning!'];
export var stabilityWarning: string[] = ['The file is not considered for uploading. please upload another file.!', 'Warning'];
//Select Sponsor
export var sponsorSuccessMessage: string[] = ['Sponsor set', 'Success'];

//Study demographic group
export var addStudyDemographicWarning: string[] = ['Please select atleast one demographic group', 'Warning!'];
export var addStudyDemographicSuccess:string[]=['Demographic group has been added successfully','Success!'];
export var addStudyDemographicError:string[]=['An error occurred while adding demographic group(s) to study','Error!'];
export var studyDemographicGroupsError:string[]=['An error occurred while getting study demogrphic groups.','Error!'];


export var visitSpecimensError: string[] = ['An error occurred while getting visit specimens.','Error!'];
export var addVisitSpecimenSuccess:string[]=['Visit Specimen has been added successfully','Success!'];
export var addVisitSpecimenError:string[]=['An error occurred while adding visit specimen(s) to study','Error!'];
export var BackUpSpecimenCannotbeAdded:string[]=['Parent specimen is not added, this operation cannot be performed','Warning!'];

export var siteCodeValidationMessage: string[] = ["site code does not exist.", "Validation"];

export var noSitesConfiguredForuser:string[]=['No sites configured for the user.','Warning!']
export var orderNumberNoResults:string[] = ["Entered order number is not correct. Please enter a valid one!","Warning"];
export var requisitionNumberNoResults:string[] = ["Entered requisition number is not correct. Please enter a valid one!","Warning"];
export var orderNumberNoAccess :string[] =["Entered order number belongs to a study and site for which you don't have access to. Please try with another order number","Warning"];
export var requisitionNumberNoAccess :string[] =["Entered requisition number belongs to a study and site for which you don't have access to. Please try with another requisition number","Warning"];
export var collectedNoResults:string[] = ["There are no results to be shown in the selected  Collection date range . Please select another date range","Warning"];
export var receiptNoResults:string[] = ["There are no results to be shown in the selected  Receipt date range . Please select another date range","Warning"];
export var visitNoResults:string[] = ["There are no results to be shown. Please refine your search","Warning"];
export var testNoResults:string[] = ["There are no results to be shown. Please refine your search","Warning"];
export var subjectNoResults:string[] = ["Entered Subject Code is not valid. Please enter a valid one", "Warning"];
export var defaultNoResults:string[] = ["No Records found for given input", "Warning"];
export var regionalLabDoesNotExistMessage: string[] = ['Lab does not exist.', 'Warning!'];
export var regionalLabStatusWarning: string[] = ['Study Lab Status must not be greater than that of the Regional Lab status.', 'Warning!'];
// Lab Related error messages
export var labDoesNotExistMessage: string[] = ['Lab does not exist.', 'Warning!'];
export var studyLabCodeNotUnique: string[] = ['This Study Lab Code is already taken for this Study! Please choose another one.', 'Warning!']
export var studyLabCodeConflictsSiteCode: string[] = ['A site exists in this study with the same code. Please enter a different code', 'Warning!']
export var studySiteCodeConflictsStudyLab: string[] = ['A regional lab exists in this study with the same code. Please enter a different code', 'Warning!']


// Study Prming Shipment Destination related messages
export var destinationDoesNotExistMessage: string[] = ['Ship to Destination Name does NOT exist.', 'Warning!'];
export var addDestinationSuccessMessage: string[] = ['New Shipment Destination added successfully.', 'Success'];
export var editDestinationSuccessMessage: string[] = ['Shipment Destination Details have been updated successfully.', 'Success'];
export var destinationUpdateConfirmation: string[] = ['Are you sure you want to update the Shipment Destination details ?', 'Confirmation!'];
